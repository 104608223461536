.searchBar {
  width: 99%;
	display: block;
	position: absolute;
	left: 0;
	top: 0;
  padding: 5px;
  background: inherit;
  box-sizing: border-box;
	z-index: 3;

  &-reset,
  &-sort {
    width: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    cursor: pointer;
  }

	&-reset {
		font-size: 14px;
		color: #999;

		&:hover {
			color: $brandColor;
		}
	}

  &-sort {
    color: var(--text-color);

    &.is-active {
      color: var(--secondary-contrast-color);
    }

    .#{$picsioButtonClassName} {
			width: 100%;
			height: 100%;
			position: static;

			&__label {
				position: relative;
			}
    }

    .sortArrow {
      position: absolute;
      top: 0px;
      right: 2.5px;
      font-size: 5px;

      &.asc {
        top: auto;
        bottom: 0px;
        transform: rotate(180deg);
      }
    }
  }

	&-content {
		cursor: text;
		position: relative;
		display: block;
		background: #1b1b1b;

		> .icon-search {
			position: absolute;
			top: 4px;
			left: 12px;
			color: #ccc;
		}

		input {
			padding: 0 60px 0 44px;
		}
	}

	@include media('<desktop') {
		width: 100%;

		&-content {
			> .icon-search {
				top: 14px;
			}
		}

		&-reset {
			right: 6px;
		}
	}
}

.searchBar-sort,
.sortMenu {
  .icon-fire {
    font-size: 20px;
  }

  .PicsioIconButton {
    margin-top: 0;
    padding: 0;

    &:hover {
      color: var(--secondary-contrast-color);
      background-color: transparent;
    }
  }
}

.picsioThemeLight {
	.searchBar {
		background: #fff;

		&-reset {
			&:hover {
				color: $brandColorLightTheme;
			}
		}

		&-content {
			> .icon-search {
				color: #afafaf;
			}
		}
	}
}
