@import 'cssVariables';

.aiIcon {
  display: flex;
  align-items: center;

  .sparks {
    &:global(.PicsioIconButton.PicsioButton--size--default) {
      color: $brandColor;

      &:hover {
        color: $brandColorHover;
      }
    }
  }

  .arrow {
    width: 24px;
    height: auto;
    cursor: pointer;

    &:global(.PicsioIconButton.PicsioButton--size--default) {
      color: #CCCCCC;

      &:hover {
        color: #FFFFFF;
      }
    }
  }

  &Menu {
    &:global(.PicsioPopper--openned.PicsioPopper--hidden[data-popper-reference-hidden="true"]) {
      visibility: visible !important;
      pointer-events: all;
    }
  }


}

:global(.picsioThemeLight) {
  .sparks {
    &:global(.PicsioIconButton.PicsioButton--size--default) {
      color: $brandColorLightTheme;

      &:hover {
        color: $brandColorLightThemeHover;
      }
    }
  }
}



