#dialogRoot {
  position: fixed;
  top: 0;
  z-index: 1200;
}

#resizer {
  position: fixed;
  z-index: 4;
}

.PicsioPopper--openned {
  /** @NOTE: picsio/ui Select's dropdown is not visible in dialog, fix below */
  z-index: 1201; /** @TODO: move to @picsio/ui */
}

.wrapperDialog {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1002; // was 4

  &:empty {
    display: none;
  }
}

/** Dialog */
.simpleDialog {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 4;
  display: flex;
  background: rgba(51, 51, 51, 0.8);

  .simpleDialogBtnCross {
    font-family: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
    background: none;
    outline: none;
    border: none;
  }

  &.errorDialog {
    .simpleDialogBox {
      max-width: 700px;
    }
    .simpleDialogIcon {
      color: #f7cd46;
      &::before,
      &::after,
      .svg-icon {
        color: #f7cd46;
      }
    }
  }

  .simpleDialogUnderlayer {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .simpleDialogBox {
    z-index: 2;
    margin: auto;
    max-width: 520px;
    width: 100%;
    background: $lightblack;
    border: 1px solid $dark-darkgray;

    @media (max-width: 767px) {
      width: 95%;
    }

    @-webkit-keyframes animatePopup {
      0% {
        -webkit-transform: scale(75%);
      }
      100% {
        -webkit-transform: scale(100%);
      }
    }
    @keyframes animatePopup {
      0% {
        -webkit-transform: scale(75%);
      }
      100% {
        -webkit-transform: scale(100%);
      }
    }
    @-webkit-keyframes animateHiddingPopup {
      from {
        -webkit-transform: translateY(0);
      }
      to {
        -webkit-transform: translateY(-150%);
      }
    }
    @keyframes animateHiddingPopup {
      from {
        -webkit-transform: translateY(0);
      }
      to {
        -webkit-transform: translateY(-150%);
      }
    }

    -webkit-animation: animatePopup 0.25s ease;
    animation: animatePopup 0.25s ease;

    &.hiddingPopup {
      -webkit-animation: animateHiddingPopup 0.15s linear;
      animation: animateHiddingPopup 0.15s linear;
    }
  }
}

.simpleDialogHeader {
  color: #fff;
  display: flex;
  height: 60px;

  .simpleDialogTitle {
    flex: 0 1 100%;
    height: 30px;
    line-height: 30px;
    padding-left: 25px;
    font-size: 24px;
    font-weight: 200;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    align-self: self-end;
    &:first-letter {
      text-transform: uppercase;
    }
    &_center {
      text-align: center;
    }

    @media (max-width: 767px) {
      padding-left: 20px;
    }
  }

  .simpleDialogBtnCross {
    flex: none;
    width: 16px;
    height: 16px;
    line-height: 16px;
    margin-top: 12px;
    margin-right: 12px;
    text-align: center;
    cursor: pointer;
    align-self: self-start;
    &:hover {
      color: #ff6600;
    }
  }

  .toolbarButton {
    margin-right: -10px;
  }
}

.simpleDialogSubHeader {
  padding-left: 25px;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0em;
}

.simpleDialogContent {
  padding: 0 0 0 23px;
  font-size: 16px;
  font-weight: 300;
  color: #ccc;
  line-height: 1.25; // 20px
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  word-wrap: break-word;
  box-sizing: border-box;
  display: flex;
  margin-top: 20px;

  .simpleDialogIcon {
    font-size: 75px;
    color: $brandColor;
    flex-shrink: 0;
    padding-right: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .simpleDialogContentInner {
    flex: 0 1 100%;
    max-height: calc(100vh - 200px);
    overflow: auto;
    padding: 16px 25px 40px 2px;

    .simpleDialogDescription {
      margin-bottom: 20px;

      span,
      a,
      p {
        overflow-wrap: break-word;
      }

      .dialog-item {
        white-space: pre-wrap;
        word-wrap: break-word;
      }

      .descriptionIcon {
        display: flex;
        i {
          margin-right: 9px;
        }
        align-items: center;
      }
    }

    .firstLine {
      display: flex;
      flex-direction: row;
      input {
        display: none;
      }
      border-bottom: 1px solid #2b2b2b;
      padding-bottom: 16px;
      cursor: pointer;
    }

    .secondLine {
      display: flex;
      flex-direction: row;
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid #2b2b2b;
      cursor: pointer;
    }

    .mobileDownloadText {
      margin-left: 20px;
    }

    p {
      font: inherit;
      padding: 0;
      margin: 0.5em 0 1em 0;
    }
    .UICheckbox__label {
      font-size: 13px;
    }

    code {
      display: block;
      padding: 5px 25px;
      margin: 1em 0;
      background: #242424;
      word-wrap: break-word;
      font: 1.1em/1.3em monospace;
    }

    a {
      font: inherit;
      color: $brandColor;
      text-decoration: none;
      &:hover {
        text-decoration: none;
        color: #ffff00;
      }
    }

    *:last-child {
      margin-bottom: 0;
    }

    .dialogLink {
      color: #ffff00;
      cursor: pointer;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    .bottomHelpLink {
      display: flex;
      align-items: center;

      &.bottomGap {
        margin-bottom: 20px;
      }

      span {
        font-size: 14px;
        margin-left: 8px;
      }

      .PicsioIcon {
        margin-top: -2px;
      }

      &:hover {
        .PicsioIcon {
          path {
            fill: #ffff00;
          }
        }
      }
    }
  }

  .tag {
    margin: 4px 4px 0 0;
    vertical-align: bottom;
  }

  @media (max-width: 767px) {
    padding: 0 0 0 20px;

    .simpleDialogContentInner {
      padding-right: 20px;
    }
  }
}

.simpleDialogFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  color: #fff;
  padding: 0 25px 25px;

  .warningText {
    font-size: 14px;
    color: $warning;
  }
}

/** Theme light */
.picsioThemeLight {
  .simpleDialog {
    .simpleDialogBox {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
      // background: #eaeaea;
      background: #fff;
    }
  }

  .simpleDialogHeader {
    color: #000;
    .simpleDialogBtnCross {
      &:hover {
        color: #cc0000;
      }
    }
  }

  .simpleDialogContent {
    color: #666;
    .simpleDialogIcon {
      color: #0099ff;
    }

    .simpleDialogContentInner {
      a {
        color: $brandColorLightTheme;
        &:hover {
          color: #0099ff;
        }
      }
      code {
        background: rgba(0, 0, 0, 0.1);
      }

      .dialogLink {
        color: #0099ff;
        cursor: pointer;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }

      .bottomHelpLink:hover {
        .PicsioIcon {
          path {
            fill: #09f;
          }
        }
      }
    }
  }

  .simpleDialogFooter {
    color: #000;
  }
}
