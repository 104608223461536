:root,
html.picsioThemeDark {
  --resizer-shadow-color: rgba(255, 255, 255, 0.5);
  --resizer-background-color: black;
  --toolbar-contact-color: white;
  --expand-shadow-color: rgba(51, 51, 51, 1);
  --toolbar-contact-color-rgb: 255, 255, 255;
}

html.picsioThemeLight {
  --resizer-shadow-color: rgba(0, 0, 0, 0.15);
  --resizer-background-color: white;
  --toolbar-contact-color: black;
  --expand-shadow-color: rgba(51, 51, 51, 0.09);
  --toolbar-contact-color-rgb: 0, 0, 0;
}

.pullToRefreshWrapper {
  height: 100%;
  transform: translateZ(0);
  overflow-y: auto;
  flex-grow: 1;

  .detailsOpener {
    position: absolute;
    right: 0;
    top: 48%;
    background: var(--resizer-background-color);
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    height: 40px;
    display: flex;
    width: 20px;
    justify-content: center;
    align-items: center;
    z-index: 1;
    // box-shadow: -4px -5px 9px 0px var(--expand-shadow-color), -6px 8px 14px var(--expand-shadow-color), -10px 0px 14px var(--expand-shadow-color);
    box-shadow: -5px 0px 6px 0px rgba(var(--toolbar-contact-color-rgb), 0.2), -8px -7px 14px rgba(var(--toolbar-contact-color-rgb), 0.2), -8px 7px 14px rgba(var(--toolbar-contact-color-rgb), 0.2);

    .PicsioButton {
      width: 100% !important;
      height: 100% !important;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      &:hover {
        background-color: var(--primary-main-color);
        color: var(--resizer-background-color);
      }
    }
  }

  .treeOpener {
    position: absolute;
    left: 0px;
    z-index: 5;
    top: 50%;
    background: black;
    background: var(--resizer-background-color);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    height: 40px;
    display: flex;
    width: 20px;
    justify-content: center;
    align-items: center;
    // box-shadow: 8px 0px 9px 0px rgba(51, 51, 51, 1), 11px -4px 14px rgba(51, 51, 51, 1), 10px 8px 14px rgba(51, 51, 51, 1);
    box-shadow: 8px 0px 9px 0px var(--expand-shadow-color), 11px -4px 14px var(--expand-shadow-color), 10px 8px 14px var(--expand-shadow-color);

    .PicsioButton {
      width: 100% !important;
      height: 100% !important;
      &:hover {
        color: var(--toolbar-contact-color);
      }
    }
  }
}
