@import 'cssVariables';


:root,
html.picsioThemeDark {
  --item-hover-background-color: #444444;
  --search-text-color: black;
  --toolbar-contact-color: #fff;
  --wrapper-background-color: #1F1F1F;
  --expand-shadow-color: rgba(51, 51, 51, 1);
  --toolbar-contact-color-rgb: 255, 255, 255;
}

html.picsioThemeLight {
  --item-hover-background-color: #CCCCCC;
  --toolbar-contact-color: #000;
  --wrapper-background-color: #FFFFFF;
  --search-text-color: white;
  --toolbar-contact-color-rgb: 0, 0, 0;
  --expand-shadow-color: rgba(51, 51, 51, 0.09);
}

#searchInput {
  position: fixed;
  left: calc(46% + 5px);
  top: 10px;
  z-index: 1;
  margin-left: -300px;
  .btnSubmitText {
    left: unset;
    right: -100px;
    min-width: 95px;
    border: unset;
    color: var(--search-text-color)
  }
  @include media('<desktop') {
    margin-left: -300px !important;
  }
}

.expand {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--search-text-color);
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  height: 20px;
  display: flex;
  width: 40px;
  justify-content: center;
  align-items: center;
  z-index: 1;
  // box-shadow: 0px 4px 9px 0px var(--expand-shadow-color), -7px 8px 14px var(--expand-shadow-color), 10px 8px 14px var(--expand-shadow-color);
  box-shadow: 0px 5px 6px 0px rgba(var(--toolbar-contact-color-rgb), 0.2), -7px 8px 14px rgba(var(--toolbar-contact-color-rgb), 0.2), 7px 8px 14px rgba(var(--toolbar-contact-color-rgb), 0.2);
  .PicsioButton {
    width: 100% !important;
    height: 100% !important;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    min-height: 20px !important;
    &:hover {
      background-color: var(--primary-main-color);
      color: var(--resizer-background-color);
    }
  }

  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: -14px;
  //   width: 80px;
  //   height: 14px;
  //   background: var(--wrapper-background-color);
  // }
}


.trial {
  background-color: var(--wrapper-background-color);
  padding: 14px 14px 0px 14px;
}


.searchFilters {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    background-color: var(--wrapper-background-color);
    position: sticky;
    z-index: 7;
    box-sizing: border-box;

    &.onlyTextSearch {
      padding: 0 !important;
      min-height: 0;
      height: 0;
    }

    .picsioLink {
      display: flex;
      align-items: center;
      // padding-top: 4px;
      // margin-left: 8px;
      margin-bottom: 4px;
      margin-top: 4px;
      justify-content: center;
      padding-left: 12px;
      box-sizing: border-box;
      max-width: 200px;
      width: auto;
      font-size: 14px;
      min-height: 30px;
      // background-color: #333;
      cursor: pointer;
      position: relative;
      margin-right: 4px;
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 200px;
  }

  &__controls {
    display: flex;
    margin-bottom: 4px;
    margin-top: 4px;
    float: right;

    .toolbarButton {
      min-width: 24px;
      width: 36px;
      height: 26px;

      .asc,
      .desc {
        width: 5px;
        height: 5px;
        position: absolute;
        right: 5px;
        top: 5px;
      }

      .toolbarDropdownWrapper {
        .toolbarDropdown {
          top: 30px;
          &.drop-active-left::after {
            right: 11px;
          }
        }
      }
      &::before {
        width: 36px;
        height: 26px;
      }
    }
  }
  &__iconBtn {
    width: 24px !important;
    height: 26px;
    margin-left: 12px !important;

    svg {
      width: 24px;
      height: 24px;

      &:hover {
        fill: #fff;
      }

      &.inactive {
        fill: #666;
        &:hover {
          fill: #666;
        }
      }
    }

    &.searchFilters__customizeIconBtn {
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
  &__iconBtn.addMore {
    path:nth-child(2) {
      fill: var(--primary-main-color)
    }
   }
}

.temporarySearchFilterItem {
  width: 12px;
  height: 30px;
  box-sizing: border-box;
  background-color: rgba(255, 204, 0, 0.5);
  border-radius: 4px;
  position: relative;
}

.firstSearchItem {
  position: relative;
  width: 5px;
  margin-left: -5px;
  display: inline-block;
}

.searchFilterItem {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 6px;
  padding-right: 6px;
  box-sizing: border-box;
  max-width: 200px;
  width: auto;
  min-height: 28px;
  background-color: #333;
  cursor: pointer;
  position: relative;
  border-radius: 6px;
  margin-right: 4px;
  height: 28px;

  &:not(first-child) {
    margin-left: 4px;
  }

  &.tmp {
    background-color: rgba(255, 255, 255, 0.05);
    height: 30px;
    animation: blink 1.3s linear infinite;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &::before {
      display: none;
    }
  }

  &__dnd {
      width: 14px;
      height: 14px;

    path {
      fill: #666;
    }
  }

  &__section {
    display: flex;
    align-items: center;

    &.center {
      justify-content: center;
    }
  }

  .PicsioButton--icon {
    color: var(--text-color);
  }
  &:not(.addFiltersBtn):not(.active):hover {
    color: var(--secondary-contrast-color);
    background-color: var(--item-hover-background-color);
  }

  &.updating {
    opacity: 0.2;
    pointer-events: none;
    transition: all 0.3s ease;
  }

  &__title {
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    color: #ccc;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: 1px;
    margin-right: 8px;
    margin-left: 8px;
  }

  &__iconBtn {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__closeIcon {
      width: 14px !important;
      height: 14px !important;
  }

  &.settingMode {
    border: 1px solid #ccc;
    color: var(--secondary-contrast-color);
    background-color: var(--background-details-edit-color);
    padding-left: 8px;
    padding-right: 8px;
  }

  &.active {
    background-color: var(--primary-main-color);

    .searchFilterItem__title {
      color: #000;
      font-weight: 400;
    }

    svg {
      path {
        fill: #000;
      }
    }
  }
}

.addFiltersBtn {
  border: 1px solid var(--primary-main-color);
  padding-right: 6px;
  height: 30px;
  margin-top: 4px;
  background-color: var(--primary-main-color);

  .searchFilterItem__title {
    color: var(--primary-contrast-color);
  }

  svg {
    width: 18px;
    height: 18px;

    path {
      fill: var(--primary-contrast-color);
    }
  }
}

.searchFilterDrop {
  min-width: 300px;
  max-height: 600px;
  height: auto;
  overflow: visible;
  background-color: var(--background-contrast-color);
  color: var(--title-color);
  z-index: 6;
  padding: 12px 12px 0 12px;

  &__operators {
    box-sizing: border-box;
  }

  &__options {
    box-sizing: border-box;
  }

  &__field {
    margin-bottom: 15px;
  }

  &__string {
    margin-bottom: 15px;
  }

  &__doubleInput {
    display: flex;
    justify-content: space-between;
    column-gap: 12px;
  }

  .react-datepicker__input-container {
    width: 100%;
  }

  &__content {
    .dropdownTreeWrapper {
      position: relative;
      left: 0;
      margin-top: 0;
      box-shadow: none;
      background-color: transparent;
    }

    .defaultColorList,
    .defaultFlagList {
      display: block;
    }

    .PicsioUIDatepickerRange {
      padding-top: 0;
    }

    .dropdownTreeList {
      min-height: 34px;
      padding-top: 5px;
    }
  }

  &__controls {
    border-top: 1px solid #444;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 20px;

    .picsioLink.disabled {
      opacity: 0.4;
    }
  }

}

.picsioThemeLight {
  .assetDetailsField {
    &.root {
      background-color: var(--background-details-edit-color);
    }

    &:hover {
      background-color: #ebebeb;
    }
  }
  .searchFilters {

    &__iconBtn {
      svg {
        width: 24px;
        height: 24px;

        &:hover {
          fill: #000;
        }

        &.inactive {
          fill: #666;
          &:hover {
            fill: #666;
          }
        }
      }

      &.searchFilters__customizeIconBtn {
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .searchFilterItem {
    background-color: #EBEBEB;
    &.active {
      background-color: var(--primary-main-color);

      .searchFilterItem__title {
        color: #FFFFFF;
        font-weight: 400;
      }

      svg {
        path {
          fill: #FFFFFF;
        }
      }
    }
    .searchFilterItem__title {
      color: #666666;
      font-weight: 400;
    }

    &.addFiltersBtn {
      background-color: var(--primary-main-color);
      .searchFilterItem__title {
        color: var(--primary-contrast-color);
        font-weight: 400;
      }
    }
  }
}
